import React from "react"
import { graphql } from "gatsby"
import rehypeReact from "rehype-react"
import MediaEmbed from "../components/MediaEmbed"
import Link from "../components/Link"
import styled from 'styled-components'

const p = styled.p`
  &:last-of-type {
    margin-bottom: .7em;
  }
`

const Center = styled.div`
  text-align: center;
  margin: .5rem 0;
`

const Votre = styled.div`
  font-size: 1rem;

  .trix-editor a {
    background: rgba(0,0,0,0.05);
    padding: 1px;
    margin: 0 -1px;
    border-radius: 2px;
    pointer-events: none;
    text-decoration: none;
    color: inherit;
  }

  .day-title {
    color: #72C4A2;
    margin-bottom: .5rem;
    font-size: 1.2em;
  }

  .padding-bottom {
    padding-bottom: 2rem;
  }

  .landing-typewriter {
    color: #111;
    border: 1px solid #bbb;
    border-radius: 3px;
    padding: 4px 8px;
    min-height: 54px;
    font-family: 'Merriweather', serif;
  }

  pre {
    white-space: initial;
  }

  blockquote, pre {
    padding-left: 14px;
  }

  pre {
    border-left: 10px rgba(255, 225, 0, 0.17) solid;
    margin-left: -24px;
    font-family: inherit;
  }

  blockquote {
    border-left: 10px #93FFD7 solid;
    margin-left: -24px;
  }

  .landing-typewriter {
    color: #111;
    border: 1px solid #bbb;
    border-radius: 3px;
    padding: 4px 8px;
    min-height: 54px;
    font-family: 'Merriweather', serif;
  }
  .window-bg {
    background: #f7f7f7;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .browser-window {
    margin: 20px;
    text-align: left;
  }

  .browser-window .trix-div {
    width: 85%;
    margin: auto;
  }

  @media all and (max-width: 40em) {
    .browser-window {
      transform: scale(0.8,0.8);
      margin: 0;
      padding: 0;
    }
  }

  .browser-window .top-bar {
    border-radius: 5px 5px 0 0;
    border-top: thin solid #eaeae9;
    border-bottom: thin solid #dfdfde;
    background: linear-gradient(#e7e7e6, #e2e2e1);
  }

  .browser-window .circle {
    height: 8px;
    width: 8px;
    display: inline-block;
    border-radius: 50%;
  }

  .browser-window .circles {
    margin: 3px 11px;
    font-size: 1rem;
    margin-top: 0;
  }

  .decoration-one {
    font-size: 1.2em;
    margin: 2em auto;
  }

  .landing-centered-small {
    width: calc(22rem + 5vw);
    max-width: 90%;
  }

  .circle-red {
    background: rgb(255, 86, 79)
  }

  .circle-yellow {
    background: rgb(255, 182, 42)
  }

  .circle-green {
    background: rgb(34, 197, 54)
  }

  .browser-window .content {
    border-radius: 0 0 5px 5px;
    background-color: #ffffff;
  }

  .browser-window .entries {
    padding-left: 10px;
  }

  .browser-window h3 {
    margin-top: 0;
    font-family: 'Yeseva One', sans-serif;
    font-size: 1.7rem;
    font-weight: 400;
    background: #F2D653;
    color: #37424A;
    background: #93FFD7;
    color: #31546D;
    padding: 1em;
    line-height: 1.2em;
  }
`

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    "media-embed": MediaEmbed,
    "a": Link,
    "votre-content": Votre,
    p,
    "notacentertag": Center
  },
}).Compiler

export default ({data}) => renderAst(data.markdownRemark.htmlAst)

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      htmlAst
      html
      frontmatter {
        title
        description
        slug
        startDate
        endDate
      }
    }
  }
`
