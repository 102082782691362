import React from "react";
import styled from "styled-components";
import Animation from "./Animation";
import Canvas from "./Canvas";
import Graph from "./animations/Graph";
import Automation from "./animations/Automation";

const AnimationsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const AnimationWrap = styled.div`
  min-height: 180px;
  max-height: 200px;
  width: 200px;
  padding: 0 50px;
  overflow: hidden;

  .automation {
    height: 200px;
  }

  display: flex;
`;

const AutomationWrap = styled(AnimationWrap)`
  width: 100px;
`;

const AnimationButton = styled.div`
  &:hover {
    transition: opacity 100ms ease-out;
    opacity: 0.8;
  }

  &:active {
    transform: scale(0.95);
    transition: transform 100ms ease-in-out;
    transition: box-shadow 100ms ease-in-out;
    box-shadow: 0 0 0 6px #0012ef50;
    border-radius: 5px;
  }
`;

const VideoWrap = styled.div`
  width: 100%;
  position: relative;
  padding-top: 56.25%;
  display: block;

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`;

const LottieAnimationWrap = styled.div`
  svg {
    display: block;
  }
`;

const MediaEmbed = ({ type, name, id }) => {
  switch (type) {
    case "vimeo":
      return (
        <VideoWrap>
          <iframe
            title={name}
            style={{ border: 0 }}
            src={`https://player.vimeo.com/video/${id}?autoplay=0&title=0&color=26E0A8&byline=0&portrait=0`}
            frameBorder="0"
            allowFullScreen
          />
        </VideoWrap>
      );
    case "lottie":
      return (
        <LottieAnimationWrap>
          <Animation autoplay={true} name={name} />
        </LottieAnimationWrap>
      );
    case "formable-animations":
      return (
        <AnimationsWrap>
          <AnimationWrap>
            <Graph />
          </AnimationWrap>
          <AutomationWrap>
            <AnimationButton>
              <Automation />
            </AnimationButton>
          </AutomationWrap>
          <AnimationWrap>
            <AnimationButton>
              <Animation name="views" autoplay />
            </AnimationButton>
          </AnimationWrap>
        </AnimationsWrap>
      );
    case "canvas":
      return <Canvas name={name} />;
    default:
      return "…";
  }
};

export default MediaEmbed;
