import anime from "animejs/lib/anime.es.js";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";

function cancelAnimation(animation) {
  let activeInstances = anime.running;
  let index = activeInstances.indexOf(animation);
  activeInstances.splice(index, 1);
}

const Container = styled.div`
  width: 100%;

  .camerapath {
    overflow: hidden;
  }

  .wrapper {
    height: 400px;
    width: 400px;
    margin-left: calc(50% - 100px);
    overflow: hidden;
    transform: scale(0.5);
    transform-origin: top left;

    //svg {
    // transform: translate(-100px, -100px);
    //  }
    .shadow {
      bcakground: inherit;
      position: absolute;
      width: 400px;
      height: 400px;
      z-index: 1;
      box-shadow: 0 0 3px 6px inset transparent;
    }

    .adjust {
      transform: translate(-100px, -20px);
    }

    circle {
      transform-box: fill-box;
      transform-origin: center center;
    }

    path,
    .c1,
    .c2 {
      opacity: 0;
    }

    .p-1,
    .p-2 {
      opacity: 0;
    }

    svg.canvas {
      opacity: 0;
    }
  }
`;

export default () => {
  const [ref, inView] = useInView({
    threshold: 0.9,
    triggerOnce: true,
  });

  const [tlAnimation, setTlAnimation] = useState();
  const [loopPathAnimation, setLoopPathAnimation] = useState();
  const [canvasAnimation, setCanvasAnimation] = useState();

  if (inView && !tlAnimation.began) {
    tlAnimation && tlAnimation.play();
    loopPathAnimation && loopPathAnimation.play();
    canvasAnimation && canvasAnimation.play();
  }

  useEffect(
    () => {
      const path = anime.path(`.path`);

      const loopDuration = 7000;

      const cameraAnimation = {
        targets: ["svg.canvas"],
        translateX: path("x"),
        translateY: path("y"),
        duration: loopDuration,
        easing: "linear",
        loop: true,
      };

      const circleAnimation = (selector) => ({
        targets: selector,
        scaleX: [0, 1],
        scaleY: [0, 1],
        opacity: [
          {
            value: 0,
            duration: 50,
          },
          {
            value: 1,
            duration: 400,
            easing: "easeOutQuad",
          },
        ],
        fill: [
          {
            value: "#5766fb",
            duration: 50,
          },
          {
            duration: 1000,
            value: "#2033E0",
            easing: "easeOutQuad",
          },
        ],
        duration: 1000,
        delay: anime.stagger(700),
      });

      const loopPathAnimation = anime({
        targets: [".p-1", ".p-2", ".c1", ".c2"],
        opacity: [
          {
            value: 1,
            duration: 100,
          },
        ],
        delay: 2000,
        autoplay: inView,
      });
      setLoopPathAnimation(loopPathAnimation);

      const canvasAnimation = anime({
        targets: ["svg.canvas"],
        opacity: 1,
        duration: 5000,
        autoplay: inView,
      });
      setCanvasAnimation(canvasAnimation);

      const pathAnimation = (selector) => ({
        targets: selector,
        strokeDashoffset: [anime.setDashoffset, 0],
        opacity: [
          {
            value: 1,
            duration: 100,
          },
        ],
        delay: 100,
        duration: 1500,
        easing: "cubicBezier(.25, 0, .35, 1)",
      });

      const tl = anime.timeline({
        loop: true,
        duration: 7000,
        update: (a) => {
          if (a.currentTime >= loopDuration) {
            a.reset();
            a.restart();
          }
        },
        autoplay: inView,
      });

      setTlAnimation(tl);

      tl.add(cameraAnimation);
      const elements = [
        {
          selector: ".c4",
          type: "circle",
          offset: 700,
        },
        {
          selector: ".c5",
          type: "circle",
          offset: 900,
        },
        {
          selector: ".c6",
          type: "circle",
          offset: 1700,
        },
        {
          selector: ".c7",
          type: "circle",
          offset: 2800,
        },
        {
          selector: ".c8",
          type: "circle",
          offset: 4000,
        },
        {
          selector: ".c9",
          type: "circle",
          offset: 5600,
        },
        {
          selector: ".c10",
          type: "circle",
          offset: 5000,
        },
        {
          selector: ".c11",
          type: "circle",
          offset: 6000,
        },
        {
          selector: ".p1",
          type: "path",
          offset: -200,
        },
        {
          selector: ".p2",
          type: "path",
          offset: 0,
        },
        {
          selector: ".p3",
          type: "path",
          offset: 1000,
        },
        {
          selector: ".p4",
          type: "path",
          offset: 2000,
        },
        {
          selector: ".p5",
          type: "path",
          offset: 3200,
        },
        {
          selector: ".p6",
          type: "path",
          offset: 4700,
        },
        {
          selector: ".p7",
          type: "path",
          offset: 4300,
        },
        {
          selector: ".pa",
          type: "path",
          offset: 4400,
        },
        {
          selector: ".p8",
          type: "path",
          offset: 5700,
        },
        {
          selector: ".p9",
          type: "path",
          offset: 6800,
        },
        {
          selector: ".p10",
          type: "path",
          offset: 7000,
        },
      ];

      elements.forEach((e) => {
        const animation = (e.type === "circle"
          ? circleAnimation
          : pathAnimation)(e.selector);

        tl.add(animation, e.offset);
      });

      return () => {
        cancelAnimation(tl);
      };
    },
    [inView]
  );

  return (
    <Container>
      <div className="wrapper">
        <div className="shadow" ref={ref} />
        <div className="adjust">
          <svg
            className="canvas"
            width="1300"
            height="1197"
            viewBox="0 0 1300 1197"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle className="c1" cx="19" cy="19" r="19" fill="#0012EF" />
            <circle className="c2" cx="148" cy="139" r="19" fill="#0012EF" />
            <circle className="c3" cx="287" cy="272" r="19" fill="#0012EF" />
            <circle className="c4" cx="399" cy="389" r="19" fill="#0012EF" />
            <circle className="c5" cx="399" cy="547" r="19" fill="#0012EF" />
            <circle className="c6" cx="527" cy="667" r="19" fill="#0012EF" />
            <circle className="c7" cx="655" cy="547" r="19" fill="#0012EF" />
            <circle className="c8" cx="784" cy="427" r="19" fill="#0012EF" />
            <circle className="c9" cx="923" cy="427" r="19" fill="#0012EF" />
            <circle className="c10" cx="784" cy="667" r="19" fill="#0012EF" />
            <circle className="c11" cx="923" cy="800" r="19" fill="#0012EF" />
            <path
              className="p-2"
              d="M255.737 241.214C249.079 235.035 192.742 182.753 179 170"
              stroke="#0012EF"
              strokeWidth="5"
              strokeLinecap="round"
            />
            <path
              className="p-1"
              d="M46 44C52.5565 50.2863 108.035 103.479 121.568 116.454"
              stroke="#0012EF"
              strokeWidth="5"
              strokeLinecap="round"
            />

            <path
              className="p1"
              d="M 287 305 L 287 367.571 C 287 379.4 296.571 389 308.363 389 C 320.156 389 361 389 366.713 389"
              stroke="#0012EF"
              strokeWidth="5"
              strokeLinecap="round"
            />
            <path
              className="p2"
              d="M 287 393.5 L 287 525.571 C 287 537.4 296.571 547 308.363 547 C 320.156 547 361 547 366.713 547"
              stroke="#0012EF"
              strokeWidth="5"
              strokeLinecap="round"
            />
            <path
              className="p3"
              d="M 399 577 L 399 645.571 C 399 657.4 408.571 667 420.363 667 C 432.156 667 480.5 667 486.213 667"
              stroke="#0012EF"
              strokeWidth="5"
              strokeLinecap="round"
            />

            <path
              className="p4"
              d="M655 580C655 585.713 655 634.058 655 645.85C655 657.643 645.4 667.213 633.571 667.213L565 667.213"
              stroke="#0012EF"
              strokeWidth="5"
              strokeLinecap="round"
            />

            <path
              className="p5"
              d="M 682 523 C 695.61 510.106 751.406 457.247 758 451"
              stroke="#0012EF"
              strokeWidth="5"
              strokeLinecap="round"
            />
            <path
              className="p6"
              d="M 815 427 C 828.789 427 885.319 427 892 427"
              stroke="#0012EF"
              strokeWidth="5"
              strokeLinecap="round"
            />
            <path
              className="p7"
              d="M681.585 572.38C688.141 578.667 743.62 631.859 757.152 644.834"
              stroke="#0012EF"
              strokeWidth="5"
              strokeLinecap="round"
            />
            <path
              className="p8"
              d="M 815 698 C 828.742 710.753 885.079 763.035 891.737 769.214"
              stroke="#0012EF"
              strokeWidth="5"
              strokeLinecap="round"
            />

            <path
              className="p9"
              d="M 923 833 L 923 895.571 C 923 907.4 932.571 917 944.363 917 C 956.156 917 997 917 1002.71 917"
              stroke="#0012EF"
              strokeWidth="5"
              strokeLinecap="round"
            />
            <path
              className="p10"
              d="M 923 921.5 L 923 1053.57 C 923 1065.4 932.571 1075 944.363 1075 C 956.156 1075 997 1075 1002.71 1075"
              stroke="#0012EF"
              strokeWidth="5"
              strokeLinecap="round"
            />

            <path
              className="pa"
              d="M923 248.5C923 257.583 923 375.942 923 394.69"
              stroke="#0012EF"
              strokeWidth="5"
              strokeLinecap="round"
            />
          </svg>
        </div>
      </div>

      <div className="camerapath">
        <svg width="1300" height="1197" viewBox="0 0 1300 1197" fill="none">
          <path
            className="path"
            d="M40.5001 8C40.5001 -67 1.50003 -299 -88 -358C-177.5 -417 -291 -395 -335.5 -358C-380 -321 -596 -162.5 -596 -520"
            strokeWidth="3"
          />
        </svg>
      </div>
    </Container>
  );
};
