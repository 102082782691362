import anime from 'animejs/lib/anime.es.js'
import React, { useEffect } from 'react'
import styled from 'styled-components'

function cancelAnimation(animation) {
  let activeInstances = anime.running
  let index = activeInstances.indexOf(animation)
  activeInstances.splice(index, 1)
}

const Container = styled.div`
  path {
    transform-box: fill-box;
    transform-origin: center center;
  }

  .wrapper {
    overflow: hidden;
    width: 100px;
    height: 100px;
  }

  .motion-paths {
    visibility: hidden;
  }
`

export default () => {
  useEffect(() => {
    let animations = []
    let timer = null

    const bottomBoxScale = [
      {
        easing: 'easeOutElastic(.5, .8)',
        duration: 50,
        value: '0.9',
      },
      {
        easing: 'easeInOutElastic(.5, .8)',
        duration: 100,
        value: '1.15',
      },
      {
        duration: 300,
        value: '1',
      },
    ]

    let globalSide = 1
    const toggleSide = (side) => (side === 1 ? 2 : 1)

    const timeline = (side) => {
      const path = anime.path(`.path${toggleSide(side)}`)

      const tl = anime.timeline()
      animations.push(tl)

      tl.add({
        delay: 100,
        targets: ['.automation circle'],
        duration: 2000,
        translateX: path('x'),
        translateY: path('y'),
        rotate: path('angle'),
        easing: 'linear',
        complete: () => {
          timer = setTimeout(() => timeline(globalSide), 1500)
        },
      })
        .add(
          {
            targets: '.rect1',
            easing: 'easeOutElastic(.5, .8)',
            fill: [
              {
                value: '#1927a7',
                duration: 200,
              },
              {
                duration: 300,
                value: '#0012EF',
              },
            ],
            scaleX: [
              {
                duration: 200,
                value: '.9',
              },
              {
                duration: 300,
                value: '1',
              },
            ],
            scaleY: [
              {
                duration: 200,
                value: '.9',
              },
              {
                duration: 300,
                value: '1',
              },
            ],
          },
          0
        )
        .add(
          {
            targets: `.rect-b${side}`,
            easing: 'easeOutElastic(.5, .8)',
            fill: [
              {
                value: '#1927a7',
                duration: 50,
              },
              {
                value: '#3d4ff7',
                duration: 100,
              },
              {
                duration: 300,
                value: '#0012EF',
              },
            ],
            scaleX: bottomBoxScale,
            scaleY: bottomBoxScale,
          },
          2100
        )
    }

    const animatepath1 = () => {
      anime({
        targets: `.path${globalSide}v`,
        strokeDashoffset: [0, (a) => anime.setDashoffset(a) - 150],
        easing: 'easeInOutSine',
        duration: 1000,
        delay: 100,
      })
    }

    const animatepath2 = () => {
      anime({
        targets: `.path${toggleSide(globalSide)}v`,
        strokeDashoffset: [0, (a) => anime.setDashoffset(a) - 150],
        easing: 'easeInOutSine',
        duration: 1000,
        direction: 'reverse',
      })
    }

    timeline(globalSide)
    animatepath1()
    animatepath2()

    document.querySelector('.automation').onclick = () => {
      globalSide = toggleSide(globalSide)

      animatepath1()
      animatepath2()
    }

    // todo: kill animations
    return () => {
      animations.forEach(cancelAnimation)
      clearTimeout(timer)
    }
  }, [])

  return (
    <Container>
      <div className="wrapper automation">
        <svg
          width="100"
          height="200"
          viewBox="0 0 332 416"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M161 152.5V119"
            stroke="#D6D6D6"
            strokeWidth="4"
            strokeLinecap="round"
          />
          <path
            className="path1v"
            d="M261 293.5V252.5C261 224.9 234 207 211 202.5C188 198 161 180.1 161 152.5V119"
            stroke="#D6D6D6"
            strokeWidth="4"
            strokeLinecap="round"
          />
          <path
            className="path2v"
            d="M61 293.5V252.5C61 224.9 88 207 111 202.5C134 198 161 180.1 161 152.5V119"
            stroke="#D6D6D6"
            strokeWidth="4"
            strokeLinecap="round"
          />
          <circle r="19" fill="#2033E0" />
          <path
            className="rect1"
            d="M191.878 102.878C198.675 95.8585 200 82.5436 200 72C200 61.0039 199.197 48.2109 191.878 41.1224C184.858 34.3246 171.544 33 161 33C150.004 33 137.211 33.8027 130.122 41.1224C123.325 48.1415 122 61.4564 122 72C122 82.9961 122.803 95.7891 130.122 102.878C137.142 109.675 150.456 111 161 111C171.996 111 184.789 110.197 191.878 102.878Z"
            fill="#0012EF"
          />
          <path
            className="rect-b2"
            d="M291.878 373.878C298.675 366.858 300 353.544 300 343C300 332.004 299.197 319.211 291.878 312.122C284.858 305.325 271.544 304 261 304C250.004 304 237.211 304.803 230.122 312.122C223.325 319.142 222 332.456 222 343C222 353.996 222.803 366.789 230.122 373.878C237.142 380.675 250.456 382 261 382C271.996 382 284.789 381.197 291.878 373.878Z"
            fill="#0012EF"
          />
          <path
            className="rect-b1"
            d="M91.8776 373.878C98.6754 366.858 100 353.544 100 343C100 332.004 99.1973 319.211 91.8776 312.122C84.8585 305.325 71.5436 304 61 304C50.0039 304 37.2109 304.803 30.1224 312.122C23.3246 319.142 22 332.456 22 343C22 353.996 22.8027 366.789 30.1224 373.878C37.1415 380.675 50.4564 382 61 382C71.9961 382 84.7891 381.197 91.8776 373.878Z"
            fill="#0012EF"
          />
        </svg>

        <svg
          width="332"
          height="416"
          viewBox="0 0 332 416"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="motion-paths"
        >
          <path
            className="path1"
            d="M 161 80 L 161 152.5 C 161 180.1 188 198 211 202.5 C 234 207 261 224.9 261 252.5 L 261 330.5"
            strokeWidth="4"
            strokeLinecap="round"
          />
          <path
            className="path2v"
            d="M61 293.5V252.5C61 224.9 88 207 111 202.5C134 198 161 180.1 161 152.5V119"
            stroke="#D6D6D6"
            strokeWidth="4"
            strokeLinecap="round"
          />
          <path
            className="path2"
            d="M 161 80 L 161 152.5 C 161 180.1 134 198 111 202.5 C 88 207 61 224.9 61 252.5 L 61 330.5"
            strokeWidth="4"
            strokeLinecap="round"
          />
        </svg>
      </div>
    </Container>
  )
}
